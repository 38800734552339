@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

html {
  scroll-behavior: smooth;
}

body{
  margin: 0px 0px 0px;
  background: #f1f1f1;
  overflow:auto;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
    cursor:default!important;
}
